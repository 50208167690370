<template>
    <div class="dosiin_page-min-height dosiin_page-max-width dosiin_padding-header-cat-top">

        <div class="dosiin_wrapper-bar dosiin_mb-16">
            <Breadcrumbs
                title="Top sản phẩm bán chạy"
                :breadcumbs="breadcumbs"
            />
            <div class="dosiin-tab-bar-pc">
                <div class="tab-bar-pc-content">
                    <div class="title-page d-flex align-items-center justify-content-between">
                        <div class="title-all-categories" v-text="$t('products.products_bestselling')"></div>
                    </div>
                </div>
            </div>
        </div>

        <Intersection
            @onIntersection="onIntersectionProductBestSeller()"
        >
            <ProductBestSellerSkeletons 
                v-if="!state.showProductBestSeller" 
                :number="30"
                gridClass="grid-33"
            />

            <section v-else class="page-section">
                <div>
                    <ul class="grid grid-33 bestsellers-list">
                        <li class="grid-item list-item bestsellers-list-item" v-for="(product , index) in state.products" :key="index">
                            <ProductItemRanking v-if="index < 3" :product="product" :rating="index+1" />
                            <ProductHorizon v-else :product="product" :rating="index+1" />
                        </li>
                    </ul>
                </div>
            </section>

        </Intersection>


        <Intersection
            @onIntersection="onIntersectionProductFavorite()"
        >
            <ProductsSliderHorizon 
                wrapperClass="page-section"
                title="Sản phẩm yêu thích"
                gridClass="grid-20"
                :products="state.productsFavorite"
                :options="{
                    slidesPerView : 5,
                    slidesPerGroup : 5,
                    spaceBetween   :9,
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                }"
            />
        </Intersection>
    </div>
</template>

<script>
    
    import { inject, reactive } from 'vue';
    import ProductHorizon from '@/components/product/ProductItemHorizon';
    import ProductBestSellerSkeletons from '@/components/skeletons/sections/ProductBeseller';
    import ProductsSliderHorizon from '@/components/sliders/ProductsHorizon';
    import ProductItemRanking from '@/components/product/ProductTopRanking';
    import DataService  from '@/services/dosiin';
    import { serialize } from '@/utlis/serialize';
    export default {
        components:{
            ProductHorizon,
            ProductItemRanking,
            ProductsSliderHorizon,
            ProductBestSellerSkeletons
        },
        setup() {
            const type = inject('type');
            const params = inject('params');
            const state = reactive({
                showProductBestSeller : false,
                products : [],
                productsFavorite : []
            });

            const breadcumbs = [
                {'title' : 'Sản phẩn bán chạy' , 'route' : '#'}
            ];

                        
            async function onIntersectionProductBestSeller () {
                const response = await DataService.fetchData(serialize({ ...params , limit : 30 }));
                if(response.data){
                    state.products = Object.values(response.data.products);
                    state.showProductBestSeller = true;
                }
            }

            async function onIntersectionProductFavorite () {
                const response = await DataService.fetchData(serialize({ type : 'best_like' , limit : 30, distinct_company : true}));
                if(response.data){
                    state.productsFavorite = Object.values(response.data.products);
                }
            }

            return {
                state,
                breadcumbs,
                type,
                params,
                onIntersectionProductBestSeller,
                onIntersectionProductFavorite
            }
        },
    }
</script>
