<template>
    <main class="product-level1-page page-new-product">
        <BackHeader>
            <template v-slot:center>
                <h2 class="text-grey-lg title-header-category">
                    {{ state.title }}
                </h2>
            </template>
            <template v-slot:right>
                <div class="back-header-right-side">
                    <router-link :to="{ name : 'search'}" class="search-icon dosiin_search-header-btn">
                        <i class="dsi dsi-search"></i>
                    </router-link>
                </div>
            </template>
        </BackHeader>

        <div class="product-page-header_wrap" >        
            <h1 class="title" v-text="state.title"></h1>        
            <h2 class="subtitle" v-text="state.des"></h2>    
        </div>

        <Intersection
            @onIntersection="onIntersectionProductBestSeller()"
        >
            <ProductBestSellerSkeletons 
                v-if="!state.showProductBestSeller" 
                :number="30"
                gridClass="grid-11"
            />

            <div v-else class="page-section">
                <ul class="grid grid-11 bestsellers-list">
                    <li class="grid-item list-item bestsellers-list-item" v-for="(product , index) in state.products" :key="index">
                        <ProductHorizon :product="product" :rating="index+1" />
                    </li>
                </ul>
            </div>
        </Intersection>

        <Intersection
            @onIntersection="onIntersectionProductFavorite()"
        >
            <ProductsSliderHorizon 
                wrapperClass="page-section"
                title="Sản phẩm yêu thích"
                gridClass="grid-33"
                :products="state.productsFavorite"
                :options="{
                    slidesPerView : 2.5,
                    slidesPerGroup : 2,
                    spaceBetween   :9,
                }"
            />
        </Intersection>
    </main>
</template>


<script>
    import { reactive ,inject } from 'vue';
    import BackHeader from '@/components/common/BackHeader';
    import ProductHorizon from '@/components/product/ProductItemHorizon';
    import ProductBestSellerSkeletons from '@/components/skeletons/sections/ProductBeseller';
    import ProductsSliderHorizon from '@/components/sliders/ProductsHorizon';
    import DataService  from '@/services/dosiin';
    import { serialize } from '@/utlis/serialize';
    
    export default {
        components:{
            BackHeader,
            ProductHorizon,
            ProductsSliderHorizon,
            ProductBestSellerSkeletons
        },
        setup() {
            const type = inject('type');
            const params = inject('params');
            const state = reactive({
                title : 'Top sản phẩm bán chạy',
                des  : 'Các sản phẩm bán chạy đến từ các thương hiệu Việt Nam',
                showProductBestSeller : false,
                products : [],
                productsFavorite : []
            });


            async function onIntersectionProductBestSeller () {
                const response = await DataService.fetchData(serialize({ ...params , limit : 30 }));
                if(response.data){
                    state.products = Object.values(response.data.products);
                    state.showProductBestSeller = true;
                }
            }

            async function onIntersectionProductFavorite () {
                const response = await DataService.fetchData(serialize({ type : 'best_like' , limit : 30, distinct_company : true}));
                if(response.data){
                    state.productsFavorite = Object.values(response.data.products);
                }
            }

            return {
                state,
                onIntersectionProductBestSeller,
                onIntersectionProductFavorite
            }
        },
    }
</script>
